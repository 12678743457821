.footer-text-warp {
  padding: 0 3rem;
}
.footer-links svg {
  height: 4.5rem;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 3rem;
}

.footer-links a {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.footer-links a img {
  height: 3rem;
  margin-bottom: 0.5rem;
}

.social-media-links {
  display: flex;
  gap: 1rem;
}
.social-media-icon img {
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: 0.5rem;
}

.contact-footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 3rem;
}
.footer-signature {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  background-color: var(--black);
  border-top: 0.6rem solid var(--accent-color);
  padding: 5rem 0 7rem 0;
}
.footer-signature p {
  font-weight: 100;
}
.sand-signature {
  font-size: 1.8rem;
  font-weight: 100;
}
.contact-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.contact-link svg {
  height: 1.6rem;
  margin-left: 1rem;
  transition: all 0.5s ease-in-out;
}
.contact-link a {
  border-bottom: 3px solid transparent;
  transition: all 0.5s ease-in-out;
}
.contact-link:hover svg {
  fill: var(--accent-color);
}
.contact-link:hover a {
  border-bottom: 3px solid var(--black);
}

@media screen and (max-width: 570px) {
  .footer-signature {
    flex-direction: column;
  }
  .footer-signature p,
  .sand-signature {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 375px) {
  .footer-links svg {
    height: 4rem;
  }
  .footer-text-warp {
    padding: 0 2rem;
  }
  .footer-links a {
    gap: 2rem;
  }
  .footer-links a img {
    height: 2.5rem;
  }
}
