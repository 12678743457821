.contact-headline-1 {
  font-size: 10rem;
  margin-bottom: 0;
}
.contact-headline-2 {
  font-size: 4rem;
  letter-spacing: -2px;
}
.contact-headline-1,
.contact-headline-2 {
  margin-left: 3rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  padding: 2.5rem;
  margin-top: -8rem;
}
.contact-form input,
.contact-form textarea {
  padding: 1rem 1.3rem;
  font-size: 1.8rem;
  line-height: 1.6;
  background-color: var(--white);
  border: 1.5px solid var(--light-grey);
}
.contact-form textarea {
  margin-bottom: 3rem;
}
.contact-form input:hover,
.contact-form textarea:hover {
  background-color: #fff;
  border: 1.5px solid black;
}
input[type="text"]:focus {
  border: 1.5px solid black;
}
input::placeholder,
textarea::placeholder {
  color: #22222247;
  letter-spacing: 1px;
  font-size: 1.6rem;
}
input[name="user_email"] {
  text-transform: lowercase;
}
.form-group {
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  gap: 1rem;
}

.form-btn {
  align-self: center;
  width: 50%;
  background-color: var(--black);
  color: #fff;
}
.form-btn:hover {
  background-color: #fff;
  color: var(--black);
}
.searchfield {
  width: 40rem;
  background-color: var(--light-grey);
  border-radius: 3pt;
  border: none;
}
.message-confirmation {
  padding: 0 6%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.confirmation-modal {
  background: rgba(255, 255, 255, 0.9);
  padding: 5rem 10rem;
  margin-top: 20%;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.confirmation-title {
  font-size: 2rem;
  margin-bottom: 1rem;

  font-weight: 600;
}

@media screen and (max-width: 750px) {
  .form-group {
    width: 100%;
  }
  .searchfield {
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .message-confirmation {
    align-items: center;
  }
  .confirmation-modal {
    width: 100%;
    margin-top: 0;
    padding: 5rem;
  }
  .form-group {
    padding: 0 1rem;
  }
  .contact-form {
    gap: 1.5rem;
    margin-top: -6rem;
  }

  .contact-headline-1 {
    font-size: 7rem;
  }
  .contact-headline-2 {
    font-size: 3.3rem;
  }
}
@media screen and (max-width: 400px) {
  .form-group {
    padding: 0 0.5rem;
  }
  .contact-headline-1 {
    font-size: 6.5rem;
  }
  .contact-headline-2 {
    font-size: 2.8rem;
  }
  .confirmation-modal {
    padding: 3rem;
  }
}
@media screen and (max-width: 330px) {
  .contact-headline-1 {
    font-size: 6rem;
  }
  .contact-headline-2 {
    font-size: 2.5rem;
  }
}
