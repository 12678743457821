.hero--section {
  height: 100vh;

  display: grid;

  background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0)
    ),
    url("/public/assets/my-photo.jpg");
  background-repeat: no-repeat;
  background-position: bottom right 25%;
  background-size: cover;
  /* background-attachment: fixed; */

  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 20vh;
  padding-left: 8vw;
}
.line-container {
  line-height: 1;
  margin-bottom: 5rem;
}

.line-text-container {
  max-width: 42rem;
}

.main-line {
  font-family: var(--font-headline);
  text-transform: uppercase;
  letter-spacing: -5px;
  font-size: 13rem;
  font-weight: 700;
}
.top-line {
  font-family: var(--family-font);
  font-size: 2.6rem;
  font-weight: 600;
  letter-spacing: -1.2px;
  padding-left: 0.8rem;

  color: var(--accent-color-dark);
}
.top-line-and {
  font-size: 3rem;
  font-weight: 900;
}

.line-text {
  font-size: 2rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
}
.line-text--1 {
  font-weight: 600;
}
.line-text--2 {
  font-weight: 300;
}

@media screen and (max-width: 1300px) {
  .main-line {
    font-size: 10rem;
  }
}
@media screen and (max-width: 1025px) {
  .main-line {
    font-size: 10rem;
  }
  .hero--section {
    background-position: bottom right 33%;
    padding-bottom: 35vh;
    padding-left: 3rem;
  }
  .line-text {
    width: 90%;
  }

  .top-line {
    padding-left: 0.3rem;
  }
}
@media screen and (max-width: 750px) {
  .hero--section {
    padding-left: 2rem;
  }
  .line-text-container {
    max-width: 40rem;
  }

  .image-container img {
    margin-left: 0;
  }
  .main-line {
    font-size: 8rem;
  }
}
/* phone */
@media screen and (max-width: 570px) {
  .hero--section {
    background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.6),
        rgba(0, 0, 0, 0)
      ),
      url("/public/assets/my-photo.jpg");
    background-position: bottom right 30%;
    padding-bottom: 17vh;
    padding-left: 2rem;
  }

  .line-container {
    margin-bottom: 3rem;
    width: 100%;
  }

  .top-line {
    font-size: 2.2rem;
  }
  .top-line-and {
    font-size: 2rem;
    margin-left: 0.3rem;
  }
  .main-line {
    font-size: 6.5rem;
    letter-spacing: -2.5px;
    font-weight: 600;
    color: var(--light-grey);
  }

  .line-text {
    font-size: 1.8rem;

    color: var(--light-grey);
  }
  .line-text--1 {
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .line-text--2 {
    font-weight: 100;
  }
}
