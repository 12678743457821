.what-i-do-section {
  background-color: var(--light-grey);
}
.what-i-do-headline {
  position: relative;
}

.cards-container {
  padding: 0 3.5rem;
  display: flex;
  gap: 5rem;
}
/* mobile menu  */
@media screen and (max-width: 975px) {
  .cards-container {
    display: flex;
    width: 80%;
    flex-direction: column;
    gap: 8rem;
  }
}
@media screen and (max-width: 650px) {
  .cards-container {
    padding: 0 1rem 0 1.5rem;
    width: 100%;
  }
}
