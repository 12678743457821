.card-container {
  width: 100%;
  padding: 5.5rem 4rem 4rem 4rem;
  background-color: #ffffff9b;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
}
.card-title {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}
.card-title img {
  height: 4rem;
}
.card-title h1 {
  font-size: 2.5rem;
  line-height: 1.2;
  margin-left: 2rem;
}
.card-slogan {
  font-size: 2rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  color: var(--accent-color);
}
.card-text {
  white-space: pre-line;
}

.card-text button {
  margin: 3rem 0;
  display: flex;
  align-items: center;
}
.card-text button svg {
  height: 2rem;
  transition: all 0s ease-in-out;
}
.card-text button:hover svg {
  fill: var(--accent-color);
}

.card-skills {
  margin-top: 0.8rem;
  font-size: 1.8rem;
}
@media screen and (max-width: 975px) {
  .card-title {
    margin-bottom: 3.5rem;
  }
}
@media screen and (max-width: 650px) {
  .card-container {
    padding: 5rem 3rem 3rem 3rem;
  }
  .card-slogan {
    font-size: 1.8rem;
  }
  .card-skills {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 400px) {
}
