.header {
  position: fixed;
  margin: 0;

  top: 0;

  width: 100vw;

  z-index: 11;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10rem;

  transition: all 1s;
  padding: 0 3rem;
}
.navbar.active {
  background-color: var(--navbar);
  box-shadow: 0 1.2rem 3rem rgba(0, 0, 0, 0.05);
}
.navbar-logos {
  display: flex;
  align-items: flex-end;
}
.logo-icon {
  height: 3.5rem;
  width: 3.5rem;
  margin-left: 2rem;
}
.logo-name-white {
  height: 2.6rem;
  opacity: 0.9;
}
.nav-list {
  display: flex;
  list-style: none;
  gap: 1rem;
}
.nav-items a {
  white-space: nowrap;
  height: 100%;
  font-size: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.063em;
  font-weight: 400;
  letter-spacing: -0.1px;
  text-decoration: none;
  color: var(--nav-links);
  opacity: 0.7;
  text-align: center;

  padding: 0.3rem 1.55rem;
  border: 1px solid transparent;
  border-radius: 50rem;
  transition: all 100ms;
}

.nav-items a:hover {
  opacity: 1;
  border: 1px solid var(--accent-color-hover);
}
.nav-items a.active {
  border: 1px solid var(--accent-color);

  opacity: 1;
}
.menu-btn {
  display: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
}
.social-media-nav-links {
  display: none;
  align-items: end;
  margin-left: 3rem;
  gap: 1rem;
}
.social-media-nav-icon:nth-child(1) img {
  width: 3.6rem;
  height: 3.6rem;
}
.social-media-nav-icon:nth-child(2) img {
  margin-bottom: 0.2rem;
  width: 3rem;
  height: 3rem;
}

/* mobile menu navbar */

@media screen and (max-width: 975px) {
  .menu-btn {
    display: block;
    width: 4.5rem;

    z-index: 99;
  }
  .logo-icon {
    display: none;
  }

  .navbar {
    padding: 0 4rem;
  }
  .social-media-nav-links {
    display: flex;
  }
  .nav-menu {
    position: fixed;
    display: none;
    box-shadow: -1px 0 2rem rgba(15, 15, 15, 0.2);
    background-color: var(--light-grey);
    transition: 350ms;
    bottom: 0;
  }
  .nav-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 10rem 1rem;
  }
  .nav-items a {
    display: inline-block;
    width: 100%;
    text-align: start;
    font-weight: 400;
    padding: 0.3rem 2.5rem;

    font-size: 2.6rem;
    letter-spacing: -0.5px;
    border: 1.5px solid var(--black);
  }

  .nav-menu.active {
    display: block;
    top: 0;
    left: 0;
    right: 0;
    transition: all 350ms ease-in-out;
  }

  .nav-items a {
    color: black;
  }
  .nav-items a.active {
    border: 1px solid var(--black);
    background-color: var(--black);
    color: var(--light-grey);
  }
}
@media screen and (max-width: 570px) {
  .menu-btn {
    width: 4rem;
  }

  .navbar {
    padding: 0 2.5rem;
  }
}
@media screen and (max-width: 400px) {
  .logo-name-white {
    height: 2.8rem;
  }
}
