/* font */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@100..700&display=swap");
@import url("https://fonts.cdnfonts.com/css/satoshi");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}
*:focus {
  /* outline: 1px solid black; */
  outline: none;
}

html {
  font-size: 62.5%;
}

html,
body,
#root {
  width: 100%;
  margin: 0;
  padding: 0;
}
body {
  overflow-y: auto;
  overflow-x: hidden !important;
  min-height: 100%;
  font-family: var(--family-font);
  font-size: 1.6rem;
  color: var(--black);
  display: block;
  box-sizing: inherit;
  scroll-behavior: smooth;
}
