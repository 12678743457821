/* color schema */
:root {
  --family-font: "Satoshi", sans-serif;
  --font-headline: "Antonio", sans-serif;

  --nav-links: #1c1d1d;
  --navbar: #fdfafaef;

  --white: #fff;
  --black: #1c1d1d;
  --black-opacity: rgba(0, 0, 0, 0.85);

  --accent-color: #f03f26;
  --accent-color-dark: #bc1600;
  --accent-color-hover: rgba(240, 61, 38, 0.25);

  --text-color: #121212;
  --text--bg: rgba(0, 0, 0, 0.65);

  --light-grey: #efefef;
  --darker-grey: #c6c6c6;
  --border-grey: #c6c6c6;

  /* --light-bg: #e9e9e8;
  --image-darker: rgba(0, 0, 0, 0.5); */
}

a {
  text-decoration: none;
  color: var(--black);
  cursor: pointer;
}
ul {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
}

/* general */

.headline-title {
  display: flex;
  justify-content: center;
}
.section {
  padding: 20rem 0;
  width: 100%;
}
.primary-headline {
  font-family: var(--font-headline);
  white-space: pre-line;
  margin-bottom: 15rem;
  text-transform: uppercase;

  font-size: 7rem;
  line-height: 1.1;
  letter-spacing: -3px;
  font-weight: 700;
}
.secondary-headline {
  white-space: pre-line;

  text-align: center;
  font-size: 2.5rem;
  line-height: 1.1;
  letter-spacing: -1px;
  font-weight: 600;
}
.bodytext {
  white-space: pre-line;
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: 0.2px;
}

.btn {
  border: none;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 1.2rem 2.8rem;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: inherit;

  background-color: transparent;
  color: var(--black);
}
.btn-square {
  border: 3px solid var(--black);
}
.btn-round {
  border: 1.5px solid var(--black);
  border-radius: 50rem;
  font-size: 1.6rem;
}
.open {
  display: block;
}
.closed {
  display: none;
}
.hidden {
  display: none;
}
.red {
  color: var(--accent-color);
}
.white {
  color: var(--white);
}

@media screen and (max-width: 1200px) {
  .container {
    overflow-x: hidden;
  }
}
@media screen and (max-width: 975px) {
  .section {
    padding: 15rem 0;
  }
}

@media screen and (max-width: 650px) {
  .primary-headline {
    font-size: 5rem;
  }
}
@media screen and (max-width: 453px) {
  .section {
    padding: 11rem 0;
  }
  .secondary-headline {
    font-size: 2rem;
  }
  .primary-headline {
    margin-bottom: 8rem;
  }
}
